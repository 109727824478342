import { useObservable, useObservableState } from "observable-hooks";
import { distinctUntilChanged, switchMap } from "rxjs";
import { Entity, EntityFactory } from "../core";

export const useEntity = <E extends Entity<T>, T>(factory: EntityFactory<E, T>, ...args: any[]) => {
  const entity$ = useObservable(
    ($inputs) =>
      $inputs.pipe(
        switchMap(([args]) => factory.from$(...args)),
        distinctUntilChanged(),
      ),
    [args],
  );
  const entity = useObservableState(entity$.pipe(distinctUntilChanged()));
  return entity;
};
